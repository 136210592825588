
import logo from './images/FF-Logo-Transparent-White.png';
import googlePlay from './images/Google-Play.png'
import provide from './images/FF-Provide.png'
import find from './images/FF-Find.png'
import schedule from './images/FF-Schedule.png'
import icon from './images/FF-Icon.png'

import { useState, useEffect } from 'react';
import * as Realm from "realm-web";

// const REALM_APP_ID = "projectff-web-rschk";
// const app = new Realm.App({ id: REALM_APP_ID });

// testing auto deploy

function App() {
  // const [dimensions, setDimensions] = useState({
  //   height: 0,
  //   width: 0,
  // });

  // useEffect(() => {
  //   window.onresize = updateDimensions();
  // }, []);

  // function updateDimensions() {
  //   setDimensions({
  //     height: window.innerHeight,
  //     widht: window.innerWidth
  //   });
  // }

  return (
    <div style={{backgroundColor: '#e6e6e6'}}>
      {/* intro panel */}
      <div class="uk-container uk-container-center uk-container-expand"
        style={{
          height: 750,
          background: "linear-gradient(rgba(0 ,0 ,0 ,0.35), rgba(0 ,0 ,0 ,0.35)), url('https://images.pexels.com/photos/1904105/pexels-photo-1904105.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260')",
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: "cover" }}>

        {/* logo */}
        <div class="uk-grid" style={{marginTop: 150}}>
          <div class="uk-width-1-2">
            <div style={{aspectRatio: 498/79, height: 47.7, width: 300}}>
              <img src={logo} alt="logo" style={{height: '100%', width: '100%', objectFit: 'cover'}} />
            </div>
          </div>
        </div>
        
        {/* description */}
        <div class="uk-grid" style={{marginTop: 75}}>
          <div class="uk-width-1-2@s uk-width-1-3@m uk-width-1-3@l uk-width-1-4@xl">
            <div style={{marginTop: 50, marginLeft: 15, color: 'white'}}>
              <h2 style={{color: 'white'}}>For our Furry Frens.</h2>
              <text>
                "Find a Fren" or "Find your Frens a Forever Home".
                FrenFinder is here to make dog adoption easier by
                connecting people who love dogs.
              </text>
              <br/><br/>
              <text style={{fontStyle: 'italic'}}>
                - Available for Open Testing on Google Play<br/>
                - Coming Soon on Apple Store
              </text>
            </div>
          </div>
        </div>

        {/* app store links */}
        <div class="uk-grid">
          <div class="uk-width-1-2">
            <a href="https://play.google.com/store/apps/details?id=app.frenfinder" target="_blank">
              <div style={{aspectRatio: 323/125, height: 60, width: 142.1}}>
                <img src={googlePlay} alt="logo" style={{height: '100%', width: '100%', objectFit: 'cover'}} />
              </div>
            </a>
          </div>
        </div>
      </div>

      {/* example panel */}
      <div class="uk-container uk-container-small uk-container-center" style={{ marginTop: 50 }}>
        <div class="uk-grid" style={{marginTop: 50}}>
          {/* provide */}
          <div class="uk-width-1-3@s uk-width-1-3@m uk-width-1-3@l uk-width-1-3@xl" style={{marginBottom: 75}}>
            <div style={{justifyContent: 'center', alignItems: 'center'}}>
              <img src={provide} alt="logo" style={{height: '100%', width: '100%', objectFit: 'cover'}} />
            </div>
            <h3 style={{textAlign: 'center'}}>Provide</h3>
          </div>

          {/* find */}
          <div class="uk-width-1-3@s uk-width-1-3@m uk-width-1-3@l uk-width-1-3@xl" style={{marginBottom: 75}}>
            <div style={{justifyContent: 'center', alignItems: 'center'}}>
              <img src={find} alt="logo" style={{height: '100%', width: '100%', objectFit: 'cover'}} />
            </div>
            <h3 style={{textAlign: 'center'}}>Find</h3>
          </div>

          {/* schedule */}
          <div class="uk-width-1-3@s uk-width-1-3@m uk-width-1-3@l uk-width-1-3@xl" style={{marginBottom: 75}}>
            <div style={{justifyContent: 'center', alignItems: 'center'}}>
              <img src={schedule} alt="logo" style={{height: '100%', width: '100%', objectFit: 'cover'}} />
            </div>
            <h3 style={{textAlign: 'center'}}>Schedule</h3>
          </div>
        </div>

      </div>

       {/* footer panel */}
       <div class="uk-container uk-container-small uk-container-center" style={{ marginTop: 100, paddingBottom: 100 }}>
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <div style={{justifyContent: 'center', alignItems: 'center'}}>
              <img src={icon} class="uk-align-center" alt="logo" style={{height: 150, width: 150}} />
            </div>
          </div>
        </div>
        
        <div class="uk-grid" style={{marginTop: 0}}>
          <div class="uk-width-1-1">
            <text class="uk-align-center" style={{textAlign: 'center'}}>© Copyright 2021 - FrenFinder LLC. - All Rights Reserved</text>
          </div>
        </div>

      </div>
      

    </div>
  );
}

export default App;
